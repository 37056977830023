/* Styles remain the same as the previous example */
#slider-container {
    width: 100%;
    margin: auto;
    overflow: hidden;
    align-items: center;
}

#main{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.truncate-five-lines{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.truncate-two-lines{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}

#slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    box-sizing: border-box;
}

.slider-btn {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: white;
    background-color: #333;
    border: none;
    // padding: 10px;
}

#prevBtn {
    left: 0;
}

#nextBtn {
    right: 0;
}

.scroll-smooth {
    overflow: auto;
}
  
.scroll-smooth::-webkit-scrollbar {
    width: 0;
    background-color: transparent; /* Optional: Set the background color to match your container */
}
